import { Component } from '@angular/core';
import { environment } from '../../environments/environment';
import { ConnectionService } from './shared/connection.service';
import { ToastService } from './shared/toast.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'aahaar-partner-ui';

  environment: string = '';
  isOnline: boolean = false;

  constructor(private connectionService: ConnectionService, private toastService: ToastService) {}

  ngOnInit() {
    this.environment = environment.environmentMode
    this.connectionService.isOnline.subscribe(status => {
      this.isOnline = status;
      if (this.isOnline) {
        this.toastService.showSuccess('Internet connection restored!')
      } else {
        this.toastService.showError('Internet connection lost!')
      }
    });
  }
}
