import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {

  screenHeight: number | undefined;
  screenWidth: number | undefined;
  isDesktop: boolean = false;
  isMobile: boolean = false;
  value: string = '';
  showForm: boolean = true;
  showPassword: boolean = false;

  constructor() {
    this.getScreenSize();
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.isDesktop = false;
      this.isMobile = true;
    } else {
      this.isDesktop = true;
      this.isMobile = false;
    }
  }

}
